@import '../theme.scss';
.journal-account{
    border-radius: 20px;
    min-height: 50%;
}


.journal-of-account {
    border-radius: 12px;
    min-height: 100%;
}
.tborder {
    border: none;
}

.total {
    border-radius: 10px;
    margin-right: 88px;
    margin-left: 250px;
}
.p-dropdown.period-dropdown{
    padding:-0.75rem 0rem;
    height:fit-content;
}
// .down {
//     width: 100%;
// }

.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 1rem;
    width: 1.5rem;
}

.bag{
    background: #c9dfd5;
    
    // background: #3e9d71;
    // opacity: 0.2;
}

.bgp1{
    background: #FAFAFA;
    border-radius: 10px;
}

.np {
    // border: 1px solid;
    // border-radius: 8px;
    // padding: 3px;
    // margin-bottom: 8px;
    // /* align-items: center; */
    // color: #9fc9a6;

    background: #ffffff;
    color: #343a40;
    border: 1px solid $primary;
    border-width: 1px 1px 1px 9px;
    padding: 0.5rem 0.5rem;
    font-weight: 700;
    margin: 0px 0px 0p 3px 4px;
    box-shadow: 1px 2px 8px 2px $primary-light !important;
    margin-bottom: 14px;
    border-radius: 5px;
}

.bgp {
    background: #FFFAF3;
}
.bgd{
    // background: #c9dfd5;
    // font-size: 17px;
    color: white;
    font-weight: bold;
    background: #216042;
    
    // opacity: 0.2;
}

.form-control:disabled.bgd {
    background-color: #216042;
    opacity: 1;
}
.bg{
    background: #216042;
}
.p-button.p-button-icon-only {
    width: 2.5rem;
    padding: 0.3rem 0;
    // background: #3e9d71;
}
.p-button{
    background: $primary;
    border: none;
}
.p-button.p-button-icon-only:hover{
    background: $primary;
    border-color: $primary;
    // background: none;
}

.bin {
    color: red;
    padding-left: 22px;
}

.plus{
    color: #35adf3;
    
}

.not svg{
    height: 1.2em ;
    width: 1.2em;
}


.heading{
    text-align: center;
}

.tot{
    text-align: end;
}

.accon {
    width: 13rem;
    // width: 100%;
}

.note{
    color: red;
    
}
.note svg {
    height: 1.5em;
    width: 1.5em;
}

.close{
    color: red;
    padding-left: 360px;    
}
.close svg {
    height: 2em;
    width: 2em;
}
.add{
    padding-left: 310px;
}

.right{

position: absolute;
top: 30px;
background-color: white;
width: 100%;
max-width: 700px;
min-height: 100%;
border-top-left-radius: 4px;
border-top-right-radius: 4px;
top: 0;
max-width: 400px;
border-radius: 0;
// left: 1615px;
right: 0;
flex-direction: column;
}


tr.heading th {
    width: 14%;
}

.a{
    min-width: 300px;
}
.b{
    min-width: 100px;
}

table{
    tbody{
        td:first-child{
            width: 20px;
        }
    }
}

.modal-backdrop{
    display: none;
    z-index: -1;
}
.modal-dialog{
    z-index: 5;
}

.p-disabled {
    // background-color: white;
    opacity: 1;
}

.form-control:disabled {
    background-color:white;
    opacity: 1;
}
input{
    background-color:white;
    opacity: 1;
}
//  .disclr:disabled{
//     background-color:whitesmoke;
//     opacity: 1;
// }

.tablerow.even{
    background: white;
}
.tablerow.odd{
    background: #d5d5d5;
}
