// dropdown - datepicker
.ant-picker-dropdown .ant-picker-content {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
}
.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
    padding: 5px 0px;
}
.ant-picker-dropdown .ant-picker-content th {
    height: 36px;
    color: #ffffff;
    vertical-align: middle;
}

//select
.cc-form-control {
    display: block;
    width: 100% !important;

    .ant-select-selector {
        height: 36px !important;
        border-radius: 0.25rem !important;
    }
}

.ant-tag.ant-tag-has-color{
    color: black;
}

.aa-search{
    .ant-input-group-wrapper-outlined .ant-input-group-addon {
        background: rgb(126 0 0 / 2%);
        border: 0px solid #d9d9d9;
    }
    .ant-input-outlined {
        background: #ffffff;
        border-width: 0px;
    }
}
.kk-btnThin{
    font-size: 14px;
    height: 33px;
}