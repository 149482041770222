// $primary : #7A6EFE;
// $primary : #3e9d71;


$secondary :  #FFA902;
$success : #41BC21;
$danger : #C20016;
$info :#35adf3;
$warning: #ffff00;

$grid:#216042;

$focus : #5A53B2;
$back : #8b80fa;

$bcolor : #2F80ED;
$gray-2: #e4e9f0;
$black:black;

$white: #ffffff;
$txtcolor: var(--kit-txtcolor-primary);
$bg-green:#ddf9ec;

$prime-green: var(--kit-color-primary);
// $primary :$prime-green;
$primary : var(--kit-color-primary) ;
$primary-dark : var(--color-primary-darker);
$primary-light : var(--color-primary-lighter);
$primary-bg: var(--bgColor-primary);
$primary-bglight: var(--bgColor-primary-light);
$primary-bgdark: var(--bgColor-primary-dark);
$border: $gray-2;

@import '~bootstrap/dist/css/bootstrap.min.css';
// @import '~bootstrap/scss/bootstrap.scss';