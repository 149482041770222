// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
// @font-face {
//     font-family: "Trade Gothic Next SR Pro Bd Cm";
//     src: url("~assets/fonts/TradeGothicNextSRPro/TradeGothicNextSRPro-BdCm.eot");
//     src: local("Trade Gothic Next Soft Rounded Pro Bold Compressed"), local("TradeGothicNextSRPro-BdCm"),
//         url("~assets/fonts/TradeGothicNextSRPro/TradeGothicNextSRPro-BdCm.eot?#iefix") format("embedded-opentype"),
//         url("~assets/fonts/TradeGothicNextSRPro/TradeGothicNextSRPro-BdCm.woff2") format("woff2"),
//         url("~assets/fonts/TradeGothicNextSRPro/TradeGothicNextSRPro-BdCm.woff") format("woff"),
//         url("~assets/fonts/TradeGothicNextSRPro/TradeGothicNextSRPro-BdCm.ttf") format("truetype"),
//         url("~assets/fonts/TradeGothicNextSRPro/TradeGothicNextSRPro-BdCm.svg#TradeGothicNextSRPro-BdCm") format("svg");
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }
@import "./theme.scss";
* {
  margin: 0;
  border: 0;
  outline: none;
  padding: 0;
  font-family: var(--primary-font);
  box-sizing: border-box;
}

html,
body,
.App {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #fafbf9;
}

html,
body {
  font-family: var(--primary-font);
}

img {
  max-width: 100%;
  height: auto;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eaeaea;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.kk-bold {
  font-weight: bold;
}
// heading

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Trade Gothic Next SR Pro Bd Cm";
  font-weight: bold;
  color: $primary;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-family: "Lato";
  font-weight: bold;
  font-size: 24px;
}

h5,
.h5 {
  font-size: 20px;
  font-family: var(--primary-font);
}
h6 {
  font-size: 14px;
  font-family: var(--primary-font);
}

p {
  font-family: var(--primary-font);
  font-size: 14px;
  color: #4d596c;
  margin-bottom: 6px;
}
.nav-link {
  font-family: var(--primary-font);
}
label {
  font-family: var(--primary-font);
}
button {
  font-family: "Lato", "sans-serif";
}
.btn,
select,
Select,
input,
p {
  font-family: "Lato", "sans-serif";
}
button:focus,
select:focus {
  outline: none !important;
}
.btn:focus {
  outline: none !important;
}
.btn:focus,
.btn:hover,
.btn:active {
  box-shadow: unset !important;
}
select:focus,
select:hover,
select:active {
  box-shadow: unset !important;
}
input:focus {
  outline-style: none;
  box-shadow: none;
}
.invalid-feedback,
.invalid-msg {
  font-size: 16px !important;

  text-align: left;
}
.arr-msg {
  color: #d8000c;
  font-family: var(--primary-font);
  background-color: #ffd2d2;
  text-align: left;
  padding: 3px 0 3px 10px;
  border-radius: 3px;
  font-size: 14px;
  margin: 7px 0px 7px 0px;
  i {
    font-size: 12px;
  }
}

.err-msg {
  color: #d8000c;
  font-family: var(--primary-font);
  background-color: #ffd2d2;
  padding: 10px 0 10px 0px;
  border-radius: 3px;
  font-size: 15px;
  margin: 7px 0px 7px 0px;
  text-align: center;
  font-weight: 600;
}
.success-msg {
  font-family: var(--primary-font);
  color: green;
  background-color: #caeeb1;
  padding: 10px 0 10px 0px;
  border-radius: 3px;
  font-size: 15px;
  margin: 7px 0px 7px 0px;
  text-align: center;
  font-weight: 600;
}
.err-message {
  font-family: var(--primary-font);
  width: 60%;
  margin: 0 230px 0 230px;
}
.required-field {
  color: #d8000c;
  font-family: var(--primary-font);
}

// Rich Editor

.rdw-editor-main {
  border: solid 1px #dee2e6 !important;
  min-height: 250px !important;
  max-height: 250px !important;
}
.public-DraftStyleDefault-block {
  margin: 0.5em 0.3em !important;
}
#editRichText {
  .modal-content {
    width: 750px;
  }
}
#editRichTextInterpretation {
  .modal-content {
    width: 750px;
  }
}
#editRichTextFeedback {
  .modal-content {
    width: 750px;
  }
}
.preview p {
  font-family: var(--primary-font);
  font-size: 14px !important;
}

.next {
  background: #1f98df;
  border: none;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  font-family: var(--primary-font);
}
.back {
  background: #fff;
  border: 1px solid #1f98df;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  font-family: var(--primary-font);
}
.pre-next-btn {
  padding: 12px;
  background: white;
  border-radius: 10px;
  font-family: var(--primary-font);
}
.down-link {
  font-family: "Lato";
  color: blue;
  text-decoration: underline;
  background: none;
  font-family: var(--primary-font);
}

//header dropdown  start
.kk-header-sec {
  .p-dropdown {
    background: #ffffff;
    border: 0px solid #ced4da !important;
    padding: 0px 4px 0px;
  }
  .p-dropdown .p-inputtext {
    padding: 7px 0px;
    font-size: 16px !important;
    font-weight: bold;
    color: #3e409d !important;
  }
  .p-dropdown .p-dropdown-trigger {
    width: 2rem !important;
  }
  .p-dropdown:not(.disabled).p-focus {
    box-shadow: 0 0 0 0.2rem $primary-light !important;
  }
}

//header dropdown end

//form start

.form {
  label {
    margin-bottom: 1px !important;
    margin-top: 10px;
  }
  .field-radiobutton {
    label {
      margin-bottom: 0px !important;
      padding-left: 11px !important;
      margin-top: 4px;
    }
  }
}

//required form field
.required:after {
  content: " *";
  color: red;
}

//////////////////////////////////////////////////button Style////////////////////////////////////////////////////

.btn,
.show > .btn {
  color: $txtcolor;
  background-color: $white;
  border-color: $border;
  &:hover,
  &:active {
    background-color: $gray-2;
    border-color: $gray-2;
  }
  &:hover:active,
  &:focus,
  &.active {
    background-color: $gray-2;
    border-color: $gray-2;
  }

  &.btn-default,
  &.btn-primary,
  &.btn-secondary,
  &.btn-success,
  &.btn-info,
  &.btn-warning,
  &.btn-danger,
  &.btn-dark {
    color: $white !important;
  }

  &.btn-primary {
    background-color: $primary;
    border-color: $primary;
    &:hover,
    &:active {
      background-color: $primary-dark;
      border-color: $primary-dark;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: $primary-dark;
      border-color: $primary-dark;

      // background-color: darken($primary, 6%) !important;
      // border-color: darken($primary, 6%) !important;
    }
  }

  &.btn-back {
    background-color: $back;
    border-color: $back;
    color: $white;
    &:hover,
    &:active {
      background-color: $back;
      border-color: $back;
      color: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: $back !important;
      border-color: $back !important;
      color: $white;
    }
  }

  &.btn-secondary {
    background-color: $secondary;
    border-color: $secondary;
    &:hover,
    &:active {
      background-color: lighten($secondary, 6%);
      border-color: lighten($secondary, 6%);
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($secondary, 6%);
      border-color: darken($secondary, 6%);
    }
  }
  &.btn-success {
    background-color: $success !important;
    border-color: $success !important;
    &:hover,
    &:active {
      background-color: lighten($success, 6%) !important;
      border-color: lighten($success, 6%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($success, 6%) !important;
      border-color: darken($success, 6%) !important;
    }
  }
  &.btn-danger {
    background-color: $danger !important;
    border-color: $danger !important;
    &:hover,
    &:active {
      background-color: lighten($danger, 6%) !important;
      border-color: lighten($danger, 6%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($danger, 6%) !important;
      border-color: darken($danger, 6%) !important;
    }
  }
  &.btn-warning {
    background-color: $warning !important;
    border-color: $warning !important;
    &:hover,
    &:active {
      background-color: lighten($warning, 6%) !important;
      border-color: lighten($warning, 6%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($warning, 6%) !important;
      border-color: darken($warning, 6%) !important;
    }
  }
  &.btn-info {
    background-color: $info !important;
    border-color: $info !important;
    &:hover,
    &:active {
      background-color: lighten($info, 6%) !important;
      border-color: lighten($info, 6%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($info, 6%) !important;
      border-color: darken($info, 6%) !important;
    }
  }

  &.btn-gray {
    background-color: $gray-2;
    border-color: $gray-2;
    color: $black;
    &:hover,
    &:active {
      background-color: lighten($gray-2, 6%);
      border-color: lighten($gray-2, 6%);
      color: $black;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($gray-2, 6%);
      border-color: darken($gray-2, 6%);
      color: $black;
    }
  }

  &.btn-outline-primary {
    border-color: $primary;
    color: $primary;
    &:hover,
    &:active {
      background-color: $primary !important;
      border-color: $primary;
      color: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: $primary !important;
      border-color: $primary;
      color: $white;
    }
  }
  &.btn-outline-secondary {
    border-color: $secondary;
    color: $secondary;
    &:hover,
    &:active {
      background-color: lighten($secondary, 6%) !important;
      border-color: lighten($secondary, 6%);
      color: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($secondary, 6%) !important;
      border-color: darken($secondary, 6%);
      color: $white;
    }
  }
  &.btn-outline-success {
    border-color: $success;
    color: $success;
    &:hover,
    &:active {
      background-color: lighten($success, 6%) !important;
      border-color: lighten($success, 6%);
      color: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($success, 6%) !important;
      border-color: darken($success, 6%);
      color: $white;
    }
  }
  &.btn-outline-danger {
    border-color: $danger;
    color: $danger;
    &:hover,
    &:active {
      background-color: lighten($danger, 6%) !important;
      border-color: lighten($danger, 6%);
      color: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($danger, 6%) !important;
      border-color: darken($danger, 6%);
      color: $white;
    }
  }

  &.btn-outline-warning {
    border-color: $warning;
    color: $warning;
    &:hover,
    &:active {
      background-color: lighten($warning, 6%) !important;
      border-color: lighten($warning, 6%);
      color: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($warning, 6%) !important;
      border-color: darken($warning, 6%);
      color: $white;
    }
  }
  &.btn-outline-info {
    border-color: $info;
    color: $info;
    &:hover,
    &:active {
      background-color: lighten($info, 6%) !important;
      border-color: lighten($info, 6%);
      color: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($info, 6%) !important;
      border-color: darken($info, 6%);
      color: $white;
    }
  }
}

//form end

// font size
.cc-font-size {
  &-0 {
    font-size: 0px !important;
  }
  &-10 {
    font-size: 10px !important;
  }
  &-12 {
    font-size: 12px !important;
  }
  &-14 {
    font-size: 14px !important;
  }
  &-16 {
    font-size: 16px !important;
  }
  &-18 {
    font-size: 18px !important;
  }
  &-20 {
    font-size: 20px !important;
  }
  &-24 {
    font-size: 24px !important;
  }
  &-28 {
    font-size: 28px;
  }
  &-30 {
    font-size: 30px;
  }
  &-32 {
    font-size: 32px;
  }
  &-36 {
    font-size: 36px;
  }
  &-40 {
    font-size: 40px;
  }
  &-48 {
    font-size: 48px;
  }
  &-50 {
    font-size: 50px;
  }
  &-60 {
    font-size: 60px;
  }
  &-70 {
    font-size: 70px;
  }
  &-80 {
    font-size: 80px;
  }
}

// // font color

.cc-text {
  &-primary {
    color: $primary !important;
  }
  &-t-primary {
    color: $primary !important;
  }
  &-primary-dark {
    color: $primary-dark !important;
  }
  &-secondary {
    color: $secondary !important;
  }
  &-success {
    color: $success !important;
  }
  &-danger {
    color: $danger !important;
  }
  &-warning {
    color: $warning !important;
  }
  &-info {
    color: $info !important;
  }

  &-white {
    color: $white !important;
  }
}

// background color
.bg {
  &-primary {
    background-color: $primary !important;
  }
  &-primary-light {
    background-color: $primary-light !important;
  }
  &-primary-dark {
    background-color: $primary-dark !important;
  }
  &-secondary {
    background-color: $primary !important;
  }
  &-success {
    background-color: $success !important;
  }
  &-danger {
    background-color: $danger !important;
  }
  &-warning {
    background-color: $warning !important;
  }
  &-info {
    background-color: $info !important;
  }

  &-white {
    background-color: $white !important;
  }
}

// quick height
.height {
  &-0 {
    height: rem(0);
  }
  &-10 {
    height: rem(10);
  }
  &-20 {
    height: rem(20);
  }
  &-40 {
    height: rem(40);
  }
  &-80 {
    height: rem(80);
  }
  &-100 {
    height: rem(100);
  }
  &-150 {
    height: rem(150);
  }
  &-180 {
    height: rem(180);
  }
  &-200 {
    height: rem(200);
  }
  &-250 {
    height: rem(250);
  }
  &-300 {
    height: rem(300);
  }
  &-400 {
    height: rem(400);
  }
  &-450 {
    height: rem(450);
  }
  &-500 {
    height: rem(500);
  }
  &-600 {
    height: rem(600);
  }
  &-700 {
    height: rem(700);
  }
  &-800 {
    height: rem(800);
  }
  &-900 {
    height: rem(900);
  }
  &-1000 {
    height: rem(1000);
  }
  &-1100 {
    height: rem(1100);
  }
  &-1200 {
    height: rem(1200);
  }
  &-0p {
    height: 0%;
  }
  &-10p {
    height: 10%;
  }
  &-20p {
    height: 20%;
  }
  &-25p {
    height: 25%;
  }
  &-33p {
    height: 33%;
  }
  &-50p {
    width: 50%;
  }
  &-66p {
    height: 66%;
  }
  &-75p {
    height: 75%;
  }
  &-80p {
    height: 80%;
  }
  &-90p {
    height: 80%;
  }
  &-100p {
    height: 100%;
  }
}
//quick min-height
.min-height {
  &-80 {
    min-height: 80px;
  }
  &-100 {
    min-height: 100px;
  }
  &-150 {
    height: 150px;
  }
  &-180 {
    min-height: 180px;
  }
  &-200 {
    min-height: 200px;
  }
  &-250 {
    min-height: 250px;
  }
  &-300 {
    min-height: 300;
  }
  &-400 {
    min-height: 400px;
  }
  &-450 {
    min-height: 450px;
  }
  &-500 {
    height: 500px;
  }
  &-600 {
    min-height: 600px;
  }
  &-700 {
    min-height: 700px;
  }
  &-800 {
    height: 800px;
  }
  &-900 {
    min-height: 900px;
  }
  &-1000 {
    min-height: 1000px;
  }
  &-1100 {
    min-height: 1100px;
  }
  &-1200 {
    min-height: 1200px;
  }
}
// quick width
.width {
  &-10 {
    width: rem(10);
  }
  &-20 {
    width: rem(20);
  }
  &-40 {
    width: rem(40);
  }
  &-50 {
    width: rem(50);
  }
  &-60 {
    width: rem(60);
  }
  &-70 {
    width: rem(70);
  }
  &-100 {
    width: rem(100);
  }
  &-150 {
    width: rem(150);
  }
  &-200 {
    width: rem(200);
  }
  &-250 {
    width: rem(250);
  }
  &-300 {
    width: rem(300);
  }
  &-350 {
    width: rem(350);
  }
  &-400 {
    width: rem(400);
  }
  &-500 {
    width: rem(500);
  }
  &-600 {
    width: rem(600);
  }
  &-700 {
    width: rem(700);
  }
  &-0p {
    width: 0%;
  }
  &-10p {
    width: 10%;
  }
  &-20p {
    width: 20%;
  }
  &-25p {
    width: 25%;
  }
  &-33p {
    width: 33%;
  }
  &-50p {
    width: 50%;
  }
  &-66p {
    width: 66%;
  }
  &-75p {
    width: 75%;
  }
  &-80p {
    width: 80%;
  }
  &-90p {
    width: 80%;
  }
  &-100p {
    width: 100%;
  }
}

.card {
  background-color: $primary-bglight !important;
  // border-color:$primary-light !important ;
}
.cc-card {
  .card {
    border-radius: 0px;
    background-color: white !important;
    border-color: white;
  }
}
.cc-greyCard{

    background-color: #F7F7F9 !important;
    border-color: #F7F7F9;
    box-shadow: 2px 2px 7.4px 0px #1B1F232B;
}

.cc-greyCard-custm{
    background-color: #ffffffb8  !important;
    border-color: #F7F7F9;
    box-shadow: 2px 2px 7.4px 0px #1B1F232B;
}

.pointer {
  cursor: pointer;
}
.card-borderless {
  border: 0px !important;
}
//pettycash
.customFont {
  font-size: 16px !important;
  color: black !important;
}
.strip {
  border-bottom: 3px solid $primary-dark;
}
.days {
  background-color: #ffffff00;
  color: #878787;
  padding-bottom: 10px;
  @media only screen and (min-width: 1439px) {
    width: 178px !important;
    margin-right: 35px;
  }
  @media only screen and (max-width: 1440px) {
    width: 130px !important;
    margin-right: 29px;
  }
  // @media only screen and (max-width:1024px) {
  //     width: 120px !important;
  //     margin-right: 29px;
  // }
  .date {
    padding: 6px 0px;
    background-color: #ffe6b9;
  }
  .day {
    background-color: white;
    padding: 12px 10px;
  }
}

.daysDisable {
  background-color: #ffffff00;
  color: #878787;
  padding-bottom: 10px;
  cursor: not-allowed;
  @media only screen and (min-width: 1439px) {
    width: 178px !important;
    margin-right: 35px;
  }
  @media only screen and (max-width: 1440px) {
    width: 130px !important;
    margin-right: 29px;
  }
  // @media only screen and (max-width:1024px) {
  //     width: 120px !important;
  //     margin-right: 29px;
  // }
  .date {
    padding: 6px 0px;
    background-color: #e3e3e3;
    color: #878787;
  }
  .day {
    background-color: white;
    padding: 12px 10px;
  }
}

.daysSelected {
  background-color: $primary-dark;
  color: #ffffff;
  border-radius: 50px 50px 0px 0px;
  // border-radius: 45px 81px 63px 3px;
  @media only screen and (min-width: 1439px) {
    width: 178px !important;
    margin-right: 35px;
  }
  @media only screen and (max-width: 1440px) {
    width: 130px !important;
    margin-right: 29px;
  }
  // @media only screen and (max-width:1024px) {
  //     width: 120px !important;
  //     margin-right: 29px;
  // }
  .date {
    padding: 6px 0px;
    background-color: $primary-dark;
    border-radius: 0px 10px 0px 0px;

    // border-radius: 0px 21px;
  }
  .day {
    padding: 12px 10px;
    border-right: 1px solid white;
    background-color: $primary-dark;
    border-radius: 10px 0px 0px 0px;
    // border-radius: 10px 0px 0px 10px;
  }
}

.border-btn {
  padding: 5px;
  border: 1px solid #3e9d71;
  border-radius: 7px;
  cursor: pointer;
  :hover {
    background-color: #3e9d71;
    color: white;
  }
}
.cc-fixed {
  background: #e99a27;
  padding: 8px;
  color: white;
}
.cc-cal {
  .p-calendar .p-datepicker {
    min-width: 75% !important;
    width: 75% !important;
  }
  .p-disabled {
    background-color: #a1a1a1 !important;
  }
}
.cc-calen {
  //  .p-datepicker {
  //     min-width: 75% !important;
  //     width: 411px !important;
  // }

  .p-datepicker {
    min-width: 411px !important;
    width: 411px !important;
  }
}

.p-datepicker {
  width: 411px !important;
}
//pettycash
.form-label {
  margin-top: 7px !important;
}
.form-control:disabled {
  background-color: #d9d9d942 !important;
}

// font color
.cc-text {
  &-primary {
    color: $primary !important;
  }
  &-secondary {
    color: $secondary !important;
  }
  &-success {
    color: $success !important;
  }
  &-danger {
    color: $danger !important;
  }
  &-warning {
    color: $warning !important;
  }
  &-info {
    color: $info !important;
  }
}

.cc-title {
  font-size: 18px;
  color: black;
  margin-bottom: 2px;
}
.cc-main-title {
  font-size: 20px;
  color: black;
  margin-bottom: 3px;
}

.fund {
  .table thead tr,
  .p-datatable .p-datatable-thead > tr > th {
    // background: #e1e1e1  !important;
    // color: #464646 !important;
    padding: 0.65rem 0.5rem !important;
    font-size: 13px;
  }
}

.tablehighlight {
  background-color: #919191 !important;
  color: white !important;
  font-weight: 600;
}

.p-disabled {
  background-color: #e5e5e5;
}
//   Layout
.cc-menu {
  // @media only screen and (min-width:1400px) {
  width: 15vw !important;
  // }

  // @media only screen and (min-width:1024px) {
  //     width: 25vw;
  // }
}
.cc-layout {
  // width: 85vw !important;
  @media only screen and (min-width: 1400px) {
    width: 85vw !important;
  }

  @media only screen and (min-width: 1024px) {
    width: 75vw;
  }
}
@media (min-width: 768px) {
  .cc-col-md-2 {
    flex: 0 0 auto;
    width: 14% !important;
  }
}
@import "./responsive.scss";

// .budget {
//     .p-dropdown {
//     width: 100%;
//     background: #FFE6B9;
// }
// }

.over-expense {
  color: red !important;
}

.ticksuccess {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 1.1rem;
  padding: 0.2rem;
  background-color: transparent !important;
  visibility: hidden;
  transition: visibility .2s ease-in;
}


.month-expense:hover .ticksuccess{
   visibility: visible;
}
.month-success .p-button-icon.p-c.pi.pi-save{
    color: blue;
    box-shadow: none;
}
.month-cancel .p-button-icon.p-c.pi.pi-times{
    color: red;
}
// .month-cancel .p-button-icon.p-c.pi.pi-save{
//     color: red;
// }



.negative-value {
  color: red;
}

.qcercile {
  color: #F8A91B;
}

.newexpense.p-fileupload .p-fileupload-buttonbar {
  background: #f8f9fa;
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  color: #343a40;
  border-bottom: 0 none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  height: 5rem;
  width: 100%;
  text-align: center;
}

.newexpcontent.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  height: 8rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.important-color {
  color: #a2a2a2 !important;
}

.form-control-custom {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.text-right-amount {
  text-align: end !important;
}

.myeye.pi-eye:before {
  content: "\e966";
  font-size: 18px !important;
}

.eye.p-button.p-button-icon-only {
  background-color: transparent;
    color: black;
    font-size: 14px;
    padding: 0;
    width: 25px;
}
.eye.p-button.p-button-icon-only:hover {
  background-color: transparent;
  color: black;

   
}

.p-tabview.p-component.hide .p-tabview-panels {
  display: none;
}

// .tabBtn {
//   background: #DDDDDD;
//   color: black;
// }

// .p-button.p-component.tabBtn {
//   background: #DDDDDD;
//   color: black;
// }

.p-tabmenu.p-component.tabBtn.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
  background: #ffffff;
  border-color: #63caf1;
  color: #63caf1;
  box-shadow: none;

}
.p-tabmenu.p-component.tabBtn.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link:focus{
  background: #ffffff;
  border-color: #63caf1;
  color: #63caf1;
  box-shadow: none;

}


.p-button.p-component.tabBtn.p-button.p-highlight {
  background: #ffffff;
  border-color: #63caf1;
  color: #63caf1;
  box-shadow: none;

}
.p-button.p-component.tabBtn.p-button.p-highlight{
  background: #ffffff;
  border-color: #63caf1;
  color: #63caf1;
  box-shadow: none;

}

// .inputclr.input[type="file"] {
//   background-color: #3E9D71;
// }

// .custom-file-upload {
//   border: 1px solid #ccc;
//   display: inline-block;
//   padding: 6px 12px;
//   cursor: pointer;
// }

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: flex;
  padding: 6px 12px;
  cursor: pointer;
  height: 3rem;
  width: 9rem;
  background: #3E9D71;
  color: white;
  border-radius: 7px
  align-items center;
  justify-content: center;
}


.p-button.p-component.focusBtn.p-button.p-component {
  background: #ffffff;
  border-color: #63caf1;
  color: #63caf1;
  box-shadow: 0 3px 3px rgba(52, 149, 253, 0.5);
  // box-shadow: none;
}
.focusBtn.p-button.p-component:active {
  background: #ffffff;
  border-color: #63caf1;
  color: #63caf1;
  // outline: none; 
  box-shadow: 0 3px 3px rgba(52, 149, 253, 0.5);
}
.p-button.p-component.focusBtn1.p-button.p-component {
  background: #f3f3f3;
  border-color: #63caf1;
  color: #957d7d;
  // box-shadow: none;
}
// .focusBtn.p-button.p-component:target {
//   outline: none; 
//   box-shadow: -2px -2px 2px rgba(52, 149, 253, 0.5);
// }
.focusBtn.p-button.p-component:focus {
  outline: none; 
  box-shadow: 0 3px 3px rgba(52, 149, 253, 0.5);
}
.focusBtn.p-button.p-component:hover {
  background: #ffffff;
  border-color: #63caf1;
  color: #63caf1;
}

.deleteUploadCancel .p-button.p-component[aria-label="Cancel"] {
  display: none;
}
.deleteUploadCancel .p-button.p-component[aria-label="Upload"] {
  display: none;
}

.pAdj .p-dialog-content {
  padding: 20px;
}


.budgetPencl .pi-pencil:before {
  color: chocolate;
}

.scrollBarReName {
  overflow-x: scroll;
  text-wrap: nowrap;
  flex: 1;
}
.scrollBarReName::-webkit-scrollbar{
  display: none;
}

.currentDate.p-datepicker table td.p-datepicker-today > span{
  background: #216042;
  color: white;
}
.currentDate.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover{
  background: #216042;
  color: white;
}

.custAccrd .p-accordion-content{
  padding: 1px;
}

.custAccrdP.p-card .p-card-body {
  padding: 0;
}
.p-accordion.p-accordion .p-accordion-header-link{
  background: white;
}
// .p-accordion.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):active .p-accordion-header-link{
//   background: aliceblue;
// }
// .p-accordion-tab.p-accordion.p-accordion-tab {
// margin: 0;
// }

.p-accordion .p-accordion-tab{
  margin: 0;
}

.deletecircle.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  color: #e17a7a;

}

.selectcolorp.p-dialog.p-confirm-dialog .p-confirm-dialog-message{
  color: red;
}

.accordbtn {
  background-color: var(--kit-color-primary);
  border-color: var(--kit-color-primary);
  padding: 0.4rem;
  color: white;
  border-radius: 5px;

}

.blink-cursor {
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: black }
}

.totalblock {
  background-color: #e2e2e2;
  color: black;
  margin-left: 0px;
  margin-bottom: 8px;
  padding: 5px 2px 5px 5px;
}

.headertext .p-accordion-header-link .p-accordion-header-text{
  display: inline-block;
  width: 100%;
}

.onhover:hover {
  background: transparent;
}

.passrange.p-password.p-component.p-inputwrapper.p-inputwrapper-filled.p-inputwrapper-focus.p-input-icon-right {
  background-color: #3E9D71;
}

.dashboard-btn {
  background: white;
  padding: 7px;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
}
.dashboard-btn-add {
  background: white;
  padding: 7px;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px

}
.dashboard-btn-add:hover {
  background: #4E8500;
  padding: 7px;
  border-radius: 5px;
  color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px

}
.dashboard-btn:hover {
  background: #4E8500;
  padding: 7px;
  border-radius: 5px;
  color: white;
}
.dashboard-btn:active {
  background: #4E8500;
  padding: 7px;
  border-radius: 5px;
  color: white;
}

.icon-design {
  font-size: 36px;
  background: #E4E4FF;
  border-radius: 24px;
  padding: 8px;
  color: #7474dd;
}
.icon-design-box {
  font-size: 36px;
  background: #FEF2D6;
  border-radius: 24px;
  padding: 8px;
  color: #FEC53D;
}
.icon-design-chart {
  font-size: 36px;
  background: #D9F7E7;
  border-radius: 24px;
  padding: 8px;
  color: #45D88E;
}

.card-box {
  border-radius: 15px;
  box-shadow: aliceblue;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;}

  .service-table-custom.table.thead tr th {
    padding: 0.5rem !important;
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    background: #3E9D71;

  }

  .tableheader.p-treetable .p-treetable-thead > tr > th {
    background: #1E6444;
    color: white;
  }

  .tableheader.p-treetable .p-treetable-wrapper table .p-treetable-tbody tr {
   background: #f2f4f8;
  }

  .userrole-remove  {
    font-size: 11px !important;
    background: #ce6057 !important;
    border: 2px solid white;
    color: white;
    padding: 2px;
}

  // .tableheader.tableheader.p-treetable .p-treetable-wrapper table .p-treetable-tbody tr {
  //   background: #ffffff;
  // }


  .user-drop {
    width: 100%;
  }

  // .p-inputswitch.p-component.p-inputswitch-checked.toggle:hover {
  //   background: #1f98df;
  // }

.toggleSwitch.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
background: #41BC21;
}
.toggleSwitch.p-inputswitch.p-focus.p-inputswitch-slider:focus {
  outline: 0 none;
  outline-offset: 0;
}

.custom-p {
  color: blue !important;
}
.custom-p-back.form-control:disabled {
  background: #0000d2ba !important;
  color: white !important;
  text-transform: capitalize;
}
.custom-p1 {
  color: white !important;
  // background-color: #41BC21 !important;
}
.custom-p2 {
 color: green !important;
}
.custom-p2-back.form-control:disabled {
 background: green !important;
 color: white !important;
 text-transform: capitalize;
}

.item-type-drp-label .p-dropdown-label.p-inputtext{
  color: transparent;
}
.item-type-drp-label .p-dropdown:not(.p-disabled).p-focus{
  box-shadow: transparent;
}

.invoice-input{
  border: 1px solid #e6e2e2 !important;
}

.borderIn {
  border: 1px solid;
}

.statusOpen {
  // background: green;
  color: #e38d06;
  padding-left: 7px;
  // text-shadow: 4px 4px 17px rgba(0,0,0,0.6);
}

.statusActive {
  // background: red;
  color: green;
  padding-left: 7px;
  // text-shadow: 4px 4px 7px rgba(0,0,0,0.6);
}
.statusOverDue {
  // background: red;
  color: #C20016;
  padding-left: 7px;
  // text-shadow: 4px 4px 7px rgba(0,0,0,0.6);
}
.statusDraft {
  // background: red;
  color: #06d4ff;
  padding-left: 7px;
  // text-shadow: 4px 4px 7px rgba(0,0,0,0.6);
}
.statusDefault {
  // background: red;
  color: black;
  padding-left: 7px;
  // text-shadow: 4px 4px 7px rgba(0,0,0,0.6);
}

.ph .p-column-header-content{
  text-align: end;
  justify-content: end;
}

.entitle-Btn {
  padding: 2px 23px;
  font-size: 16px;
  border-radius: 5px;
  height: 32px;
}

 .entitle-model .p-dialog .p-dialog-content{
  background: #a96b6b;
  color: #495057;
  // padding: 0 1.5rem 2rem 1.5rem;
}

 .p-dropdown.p-component.p-inputwrapper .model-dropdown {
  width: 100%;
}

.addservice.p-dialog.p-confirm-dialog .p-confirm-dialog-icon{
  color: #c0c020;
}

.passwordInput .p-inputtext.p-component.p-password-input {
width: 100%;
}

.PrimaryRole{
  color: #40ADEC !important;
}

.pSideBarWidth.p-sidebar .p-sidebar-header + .p-sidebar-content {
  width: 105%;
}

.pnlStl .p-tabview-panels {
padding: 0;
}
.pnlStl1 .p-tabview-nav {
background: none;
}

.accHeader .p-accordion-header-text{
  width: 100%;
}

.tbmrgn {
  margin: 0 !important;
}

.urole .p-treenode:has(.p-treenode-children) .p-treenode:has(.p-tree-toggler-icon.pi-chevron-right) .p-tree-toggler.p-link{
  padding: 0 !important;
  width: fit-content !important;
}

.urole .p-treenode:has(.p-treenode-children) .p-treenode:has(.p-tree-toggler-icon.pi-chevron-right) .p-treenode-icon{
  display: none;
}

.bg-success1{
  background: #3E9D71 !important;
}

.common-header.p-datatable .p-datatable-header{
  background: #ffffff;
  color: #343a40;
  border: 1px solid $primary;
  border-width: 1px 1px 1px 9px;
  padding: 1rem 1rem;
  font-weight: 700;
  margin: 0px 0px 0p 3px 4px;
  box-shadow: 1px 2px 8px 2px $primary-light !important;
  margin-bottom: 14px;
}

.estimate {
  background: #DFFCEE;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.cust-ant .ant-picker-content{
  width: auto;
}

.status-color {
  padding: 2px 10px;
  border-radius: 10px;
  display: inline-block;
}



.card-bill{
  background: #FDFDFD;
    border: 1px solid #D3D7DD;
}
.cc-alignend{
  text-align: end;
}

.p-toolbar.p-component.nExp {
  padding: 0px;
 background-color: transparent;
 border: none;

}

.antd-header .ant-table-thead tr > .ant-table-cell {
  background-color: #EAF2E3;
}

.amount-align .p-column-header-content {
justify-content: end;  
}

.antMdl .ant-modal-content {
  padding: 50px 80px;
background-color: #FAFBFB;
height: 273px;
}
.antMdl .ant-modal-content .ant-modal-title{
background-color: #FAFBFB;
}

.antBTn-clr .ant-btn-primary.ant-input-search-button {
  background-color: #143444;
}

.cust-form-control {
    display: block;
    padding: 0.375rem 0.75rem;
    flex: 1;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 33px;

}

.drp-lbl .p-dropdown-label.p-inputtext {
  display: flex;
  align-items: center;
}

.p-toast-top-right {
  top: 80px !important;
}

.antd-header .ant-table-thead tr > .ant-table-cell {
  background-color: #EAF2E3;
}

.amount-align .p-column-header-content {
justify-content: end;  
}

.antMdl .ant-modal-content {
  padding: 50px 80px;
background-color: #FAFBFB;
height: 273px;
}
.antMdl .ant-modal-content .ant-modal-title{
background-color: #FAFBFB;
}

.antBTn-clr .ant-btn-primary.ant-input-search-button {
  background-color: #143444;
}

.cust-form-control {
    display: block;
    padding: 0.375rem 0.75rem;
    flex: 1;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 37px;

}

.drp-lbl .p-dropdown-label.p-inputtext {
  display: flex;
  align-items: center;
}

.new-purchase-order-card-algn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-drop-search.ant-space {
  width: 100%;
}

.ant-drop-search > .ant-space-item:first-of-type{
 flex: 1;
}


.customer-tab .p-tabview-panels {
  background: #ffffff;
  border: 0 none;
  color: #495057;
  border-bottom-right-radius: 6px;
  padding: 0;
  border-bottom-left-radius: 6px;
}


// .cust-heightp .p-datatable-wrapper .p-datatable-table {
//   min-height: 400px;
// }

.bx-shadow {
  box-shadow: 0 4px 4px #00000040;
}

