@import './assets/styles/theme.scss';
@import './assets/styles/app.scss';
@import './assets/styles/antd.scss';
// @import '../src/assets/styles/theme.scss';
// @import '../src/assets/styles/app.scss';

:root{
  --kit-color-primary: #3e9d71;
  --color-primary-darker: #1e6444;
  --color-primary-lighter: #baedd5;
  --bgColor-primary: #f7f8f9;
  --bgColor-primary-light: #fff;
  --bgColor-primary-dark:#e4e4e4;
  --kit-txtcolor-primary:#000;
  --primary-font:'Montserrat', sans-serif;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #f5f6fb;
}
.App {
  text-align: left;
}
html,
body,
.App {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    // background: $primary-bg;
    background: #fafbf9;
    color: $txtcolor;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@import './assets/styles/themeCustom.scss';