//custom theme scss
@import './theme.scss';

////////////////////////////////////////////dialog/////////////////////////////////////////
.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #5a5a5a;
  padding: 0.5rem !important;
}

.table thead tr, .p-datatable .p-datatable-thead > tr > th{
  // background: $primary-dark;
  // color:#fff;
  background:#EAF2E3;
  color:#000;
  padding: 0.65rem 1.5rem !important;
}
.table thead tr, .p-datatable .p-datatable-tbody > tr > td{
  padding: 0.65rem 1.5rem !important;
 
}
.table {
  tbody{
    
    tr{
      td:first-child{
        border-left:1px solid #e9e4e4;
      }
    td{
      border-right:1px solid #e9e4e4;
    }
  }
  }
}
.p-datatable .p-datatable-thead > tr > th:first-child, .table thead tr th:first-child{
  border-top-left-radius: 0px;
  width: 10%;
}
.p-datatable.chofacc .p-datatable-thead > tr > th:first-child, .table thead tr th:first-child{
  // border-top-left-radius: 5px;
  width: 15%;
}
.p-datatable .p-datatable-thead > tr > th:last-child, .table thead tr th:last-child{
  border-top-right-radius: 0px;
}
////////////////////////////////////////////////table////////////////////////////////////

//table-header
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.3rem 1.5rem;
  border-radius: 5px;
  
}
// input{
//   width: 250px;
// }
.p-datatable .p-datatable-tbody>tr:hover {
  // background:  $primary-light;
  background: #baedd547;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd:hover {
  background: $primary-light;
}
.p-datatable{
  .p-datatable-tbody{
    tr.p-datatable-emptymessage{
      td{
        text-align: center;
        
      }
    }
  }
}

.p-datatable .p-datatable-header {
  // background: #98a8b9;
  // color: #343a40;
  // border: 1px solid #2965a1;
  // border-width: 1px 1px 1px 9px;
  // padding: 1rem 1rem;
  // font-weight: 700;


  background: #ffffff;
  color: #343a40;
  border: 1px solid $primary;
  border-width: 1px 1px 1px 9px;
  padding: 1rem 1rem;
  font-weight: 700;
  margin: 0px 0px 0p 3px 4px;
  box-shadow: 1px 2px 8px 2px $primary-light !important;
  margin-bottom: 14px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: $primary-light;
  border-color:$primary-light;
  color: $primary;
}

// .kk-header-sec .p-dropdown .p-inputtext {
//    padding: 7px 0px !important; 
// }

.kk-table-header {

  .p-input-icon-left>i:first-of-type,
  .p-input-icon-left>svg:first-of-type,
  .p-input-icon-left>.p-input-prefix {
    left: 0.55rem;
  }

  .search {
    // padding: 4.45rem 0.15rem 0.45rem 1.85rem;
    width: 250px;
  }
}

/////////////////////////////////////////multiselect//////////////////////////////////////
.p-multiselect .p-multiselect-label {
  padding: 0.25rem 0.75rem !important;
}
.p-multiselect .p-multiselect-trigger {
  width: 2.5rem;
}
// .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
 
// }


//dropborder
.p-dropdown:not(.p-disabled).p-focus {
  border-color: $primary;
  box-shadow: 0 0 0 0.1rem $primary-light;
}

.p-dropdown {
  padding: 0.25rem 0rem;
}
// .p-dropdown.acnt{
//   .p-dropdown-panel{
//     .p-dropdown-items{
//       .p-dropdown-item{
//         padding: 0.25rem 1.25rem;
//       }
//     }
//   }
// }
.p-dropdown-panel .p-dropdown-items {
  padding: 0rem 0;
}
// .p-dropdown-panel .p-component .p-connected-overlay-enter-done .p-dropdown-items .p-dropdown-item{
//   padding: 0.25rem 1.25rem ;
// }
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.25rem 1rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-bottom: 7px;
  padding-top: 7px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.25rem 0.5rem;
  color: #343a40;
  background: #ffffff;
  font-weight: 700;
}

// .p-dropdown-item-group{
//   padding-left: 10px;
// }
//checkbox

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $primary;
  background: $primary;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: $primary;
  background: $primary;
  color: #ffffff;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #495057;
  background: #EEF2FF;
}


.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: $primary;
  background: #EEF2FF;
}

// .p-dropdown-label.p-inputtext.p-placeholder{
//   padding:0 !important;
// }
.p-inputtext:enabled:hover {
  border-color: $primary;
}
// .p-inputtext{
  
// }

.p-inputtext:enabled:focus {

  box-shadow: 0 0 0 0.1rem $primary-light;
  border-color: $primary;
}

////////////////////////////////prime react button//////////////////////////////////////
.p-button {
  color: #ffffff;
  background: $primary;
  border: 1px solid $primary;

}

.p-button:not(button):not(a):not(.p-disabled):active {
  background: $primary;
  color: #ffffff;
  border-color: $primary;
}

.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: $primary;
  color: #ffffff;
  border-color: $primary;
}

.p-button:focus {
  box-shadow: 0 0 0 4px $primary-light;
}

///////////////////////////////////////select///////////////////////////////
.p-dropdown .p-dropdown-trigger {

  width: 2rem;
}

.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 32px;
  padding:0px;
}


.p-dropdown:not(.p-disabled):hover {
  border-color: $primary;
}


/////////////////////////////////////multiselect//////////////////////////////
.p-multiselect:not(.p-disabled):hover {
  border-color: $primary;
}

/////////////////////////////////toggle btn /////////////////////////////// 
.p-togglebutton.p-button.p-highlight {
  background: $primary;
  border-color: $primary;
  color: #ffffff;
  padding: 7px 6px;
  width: 114px;
}

.p-togglebutton.p-button.p-highlight:hover {
  background: $primary;
  border-color: $primary;
  color: #ffffff;
}

.p-togglebutton.p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  padding: 7px 6px;
  width: 114px;
}

//VENDOR&CUSTOMER(RAJESH)
label {
  font-size: 14px;
}

p {
  color: black !important;
}

// .p-dropdown .p-dropdown-trigger {
//   width: 1.5rem;
// }

// .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
//   padding: 0.3rem;
// }
input[type="radio"] {

  accent-color: $primary;

}

.p-calendar .p-inputtext {
  padding: 7.5px;
  border: 1px solid lightgray;
}

// .p-calendar .p-component {
//   border: none;
// }

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: $primary;
  border-color: $primary;
  color: white;
  outline: none;
}

.p-tabview-nav-content {
  border: none;
  font-size: 14px;
  border-color: $primary;
}

.p-tabview .p-tabview-nav {
  border-width: 0 0 1px 0 !important;
  border-color: #e7e7e7;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: $primary;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0.75rem;
  border-color: #e7e7e7;
}

.p-calendar .p-inputtext {
  padding: 7.5px;
  border: 1px solid lightgray;
}
// .p-inputtext{
//   padding: 7.5px !important;
//   border: 1px solid lightgray !important;
// }
.p-datepicker-other-month {
  visibility: hidden;
}

////////////// form control input //////// 
.form-control:enabled:focus {
  box-shadow: 0 0 0 0.2rem $primary-light;
  border-color: $primary;
}

.form-control:enabled:hover {
  border-color: $primary;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.clrStyle{
  font-size: 20px;
  padding-left: 10px;
  color: black;
}

.circle{
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border-radius: 100%;
  border: 1px solid #dadada;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}

.accordLeft{
  .p-accordion-header-text{
    width: 100% !important;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 10px;
    border-radius: 2px !important;

  }

  .p-accordion .p-accordion-tab {
    margin-bottom: 0px;
  }
}


.cc-calendar {
  .p-disabled {
    background-color: #b5b5b5;
    opacity: 1;
}
}


.image-text{
  color: $primary-dark;
  font-weight: bolder;
}
.ledger{
  .p-rowgroup-header{
      background: #d7d7d7 !important;
  }


  //multiselect
  
}

// .mm{
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-disabled {
    // .p-disabled{
        display: none !important;
    // }
  }
// }
.user-icon{
  font-size: 20px;
}
.icon-lock{
  font-size: 20px;
}

table{
  thead{
      tr{
          background:$grid;
          th{
             padding:0.5rem !important;
             font-size: 16px;
             color:black;
             font-weight: 500;
             border-color: #dee2e6 !important;
          }
          th:first-child{
              border-top-left-radius: 8px;
              width: 300px;
          }
          th:last-child{
              border-top-right-radius: 8px;
          }
      }
  }
  tbody{
      tr{
          .bck-clr{
              background: #f3f3f3;
              font-weight: 500;
              font-size: 15px;
          }
          td{
              font-size: 15px;
              padding:0.5rem;
          }
          td:first-child{
              border-left:1px solid #f1f1f1;
          }
          td:last-child{
              border-left:1px solid #f1f1f1;
          }
      }
  }
}

.p-sidebar-right .p-sidebar{
  width: 30rem !important;
}
.p-sidebar .p-sidebar-header{
  display: none;
}
.user-detail{
  .p-tabview{
    .p-tabview-nav{
      li.p-highlight .p-tabview-nav-link{
        background: $white;
        color:$primary;
      }
    }
    .p-tabview-panels{
      padding: 1.25rem 0;
    }
  } 
}

.bg-green{
  background: $bg-green;
}

//tree

.parent-node {
  // background: #e1e1e1;
  border-radius: 0;
  padding: 5px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 600;
}

.child-node {
  background-color: #fff;
  width: 100%;
}

.leaf-node {
  background-color: #fff;
  width: 100%;
}

.tree-node {
  display: flex;
  flex-direction: column;
  padding: 5px;

  .tree-node-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pi-plus {
      background: #4688ba;
      color: #fff;
      border: 1px solid #fff;
      margin: 0px;
      font-size: 9px;
      padding: 3px;
    }

    .pi-times {
      background: #c36a6a;
      color: #fff;
      border: 1px solid #fff;
      margin: 0px;
      font-size: 9px;
      padding: 3px;
    }
  }

}



.p-tree {
  background: #f4f4f4;
  color: #495057;
  border-radius: 0px;
  padding: 20px;

  .p-tree-container .p-treenode {
    overflow: hidden;
    padding: 0 !important;
  }

  .p-treenode-children {

    .p-treenode-content.p-treenode-selectable {
      background-color: #fff !important;
      border: 1px solid #d3dedf;
      border-radius: 0px;
      font-size: 13px;
       padding: 1px !important;
    }

    .p-tree-toggler {
      margin: 0px !important;
      padding-right: 10px !important;
      width: 35px !important;
      height: 25px !important;

      &:focus{
        box-shadow: 0 0 0 0.2rem #fff !important;
      }
    }

    button.p-tree-toggler.p-link {
      background: #fff !important;
      border: 0px !important;
      border-radius: 0px !important;
  
      &:enabled:hover {
        background: #fff !important;
      }
  
      svg {
        background: #80aa89 !important;
        border: 1px solid #fff !important;
        color: #fff !important;
        padding: 2px !important;
  
      }
  
    }
  }

  .p-treenode.p-treenode-leaf {
    .p-treenode-content.p-treenode-selectable {
      background-color: #fff !important;
      border: 1px solid #d3dedf;
      border-radius: 0px;
      font-size: 13px;
      padding: 1px !important;
    }

    button.p-tree-toggler.p-link {
      display: none;
    }
  }

  .p-tree-container .p-treenode .p-treenode-content {
    width: 100%;
    padding: 0;

    .p-tree-toggler {
      margin: -5px;
      width: 36px;
      height: 36px;
    }
  }

  .p-treenode-content.p-treenode-selectable {
    border-radius: 0;
    width: 100%;
  }


  button.p-tree-toggler.p-link {
    // background: #e1e1e1 !important;
    border: 0px !important;
    border-radius: 0px !important;
    .p-tree-toggler-icon.pi-chevron-down{
      padding:0.20rem;
      background: $primary;
      color:$white;
      font-size: 0.65rem;
      width: 1.54em;
    }

    &:enabled:hover {
      background: #e1e1e1 !important;
    }

    svg {
      background: #80aa89 !important;
      border: 1px solid #fff !important;
      color: #fff !important;
      padding: 2px !important;

    }

  }

}

//TreeTable 
.p-treetable {
  .p-treetable-tbody {
    button.p-treetable-toggler.p-link.p-unselectable-text {
      color: #f4f4f4 !important;
      width: 0 !important;
      height: 0 !important;
      margin-right: 0 !important;
    }

    tr>td {
      padding: 5px !important;
    }

    .p-checkbox .p-checkbox-box {
      background: #e3eed8 !important;
      border-color: #7eb97e !important;
    }

    .p-checkbox .p-checkbox-box .p-checkbox-icon {
      background: #7eb97e !important;
      border-color: #7eb97e !important;
      color: #7eb97e !important;
    }
  }
}

///@at-root
/// 
///
.tree-table {
  .p-treetable-tbody {
    tr {
      &.p-treetable-parent {
        background-color: green;
      }

      &.p-treetable-child,
      &.p-treetable-leaf {
        background-color: lightgreen;
      }
    }
  }
}
.p-treetable.service-tbl{
  .p-treetable-thead{
    tr{
      th{
        background: #d4d4d4;
      }
      .service-tbl-name.p-resizable-column{
        width: 30rem;
      }
    }

  }
  .p-treetable-tbody{
    tr{
      td{
        background:white;
      }
      .service-tbl-name{
        background: #f8f9fe;
        
      }
    }
  }
}
.user-type-role{
  background:#f4f4f4;
}
.p-inputswitch.service-switch, .p-inputswitch.p-inputswitch-checked{
   .p-inputswitch-slider{
    background: $success;
   }
  height:24px;
}
.p-inputswitch.service-switch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider{
  background-color: $success;
}

// Checkbox styles
.tree-table-container {
  .p-treetable {
    .p-treetable-tbody {
      button.p-treetable-toggler.p-link.p-unselectable-text {
        color: #f4f4f4 !important;
        width: 0 !important;
        height: 0 !important;
        margin-right: 0 !important;
      }

      tr>td {
        padding: 5px !important;
      }

      .p-checkbox .p-checkbox-box {
        background: #e3eed8 !important;
        border-color: #7eb97e !important;
      }

      .p-checkbox .p-checkbox-box .p-checkbox-icon {
        background: #7eb97e !important;
        border-color: #7eb97e !important;
        color: #7eb97e !important;
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  .checkbox-parent {
    // border: 1px solid #d3dedf !important;
    // padding: 5px;
  }

  .checkbox-parent .p-checkbox .p-checkbox-box {
    background-color: lightgreen !important;
  }



  .checkbox-leaf {
    background: #fff !important;
    border-color: #7eb97e !important;
    margin-left: 28px !important;
    border: 1px solid #d3dedf !important;
    padding: 5px;
    margin-top: -5px;
    margin-bottom: -6px;
    margin-right: -5px;

  }

  .checkbox-leaf .p-checkbox .p-checkbox-box {
    background-color: green !important;
  }

  .checkbox-leaf .p-checkbox .p-checkbox-box.p-highlight {
    background-color: green !important;
  }
}




//budget-forecasting 
.even{
  background: #F1F2F6;
}
.odd{
  background: #8DC54066;
}

.teven{
  background: rgb(219, 219, 219);
}
.odd{
  background: #8DC54066;
}

.p-card .p-card-body {
  padding: 0.8rem;
}

.p-card .p-card-content {
   padding: 0rem 0; 
}

 .p-dropdown .p-inputtext {
  padding: 4px 4px;
  // color: white;
}

  .yr-down {
    width: 15rem;
    background: #FFE6B9;
    // color: black;
}

.budget-drop {
  width: 100%;
    background: #FFE6B9;
}
.newdrop.p-dropdown {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
} 
  
 

.sub-cat{
  width: 15rem;
  background: #E8E8E8;
  color: black;
}

.p-divider.p-divider-horizontal {
  margin: 0;
  padding: 0 1.25rem;
}

.right-fixed{
  position:fixed;
  z-index: 999;
  right:24px;
  left:335px;
  background: #f8f9fc;
  margin-top: -142px;
}

.card-fixed{
  margin-top: 145px;
  
}

.abc{
  height: 1000px;
  overflow: auto;
}
.budget-category-link.underline-hover:hover {
  text-decoration: underline !important; /* Apply underline on hover */
}

.cc-hover-line{
  &:hover{
    text-decoration: underline;
  }
}

.cc-hover-bold{
  &:hover{
    font-weight: bold;
  }
}
.cc-edit{
    cursor: pointer;
}

//new expense
.newexpense1 {
  background: #E9F2EE;
    margin: 0.1rem;
    border-radius: 9px
}

.uploadFile {
  display: flex;
    background: #E9F2EE;
    /* height: 150px; */
    /* width: 324px; */
    /* align-content: flex-end; */
    justify-content: space-evenly;
    flex-wrap: wrap;
    /* left: 6px; */
    position: relative;
    flex-direction: column;
    padding: 0;
}

.upload-btn .p-button.p-fileupload-choose {
  width: 100%;
}
.container-ht{
  height: calc(100vh - 175px) !important;
  overflow-y: auto;
}
.tabb .p-tabview .p-tabview-panels {
  background-color: #F7F7F7;
  padding: 0;
  width: 200%;
}

.inr-input .p-inputgroup {
  height: 39px;
  // width: 272px;
  padding: 0;
}

.newdrop {
  .p-dropdown {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    width: 100%;
  }
}

 .p-treetable {
  .p-treetable-tbody {
   

    tr>td {
      padding: 5px !important;
    }

    .p-checkbox .p-checkbox-box {
      background: #e3eed8 !important;
      border-color: #7eb97e !important;
    }

    .p-checkbox .p-checkbox-box .p-checkbox-icon {
      background: #7eb97e !important;
      border-color: #7eb97e !important;
      color: #7eb97e !important;
    }
  }
}

.userrole .p-treetable-toggler.p-link.p-unselectable-text {
  height: 3rem !important;
}

.p-treetable {
  .p-treetable-tbody {
    tr{
      td{
          
          button.p-treetable-toggler{
              display: unset;

          }
      }
     }
    button.p-treetable-toggler.p-link.p-unselectable-text {
      width: unset !important;
      height: unset !important;
      margin-right: 0 !important;
      color:unset !important;
    }
    
  }
}


.p-treetable{

  .p-treetable-wrapper{
      table{
          .p-treetable-tbody{
              
            
          }
      }
  }
}
 .p-treetable-thead .sidetree-header.p-resizable-column {
   display: none !important;
  //  width: unset !important;
}
