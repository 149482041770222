
 .final{
     width: 100%;
 }
 .p-dropdown.unit-dropdown {
    .p-inputtext{
        padding:6px 4px;
    }
 }
 .p-dropdown{
    .p-inputtext{
        padding: 4px 4px;   
   }
 } 
 .p-component{
     font-size: 14px;
 }  

// .scheduler-border{
//     border-radius: 20px;   
// }
.p-togglebutton.item-status.p-button{
padding: 10px 6px !important;
}
.p-togglebutton.item-status.p-button.stat{
    height:31px;
    padding:6px;
}

 fieldset.scheduler-border {
    // border: 0.5px groove whitesmoke!important;
    padding: 0 1.2em 1.2em 1.2em !important;
    border-radius: 8px;
    border: 2px solid #f1f1f1 ;   
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
}

legend.scheduler-border {
    font-size: 1em !important;
    font-weight: bold !important;
    text-align: left !important;
    border:none;
    width:100px;
    
 }
// .input-group  {
//     width: 105%;
// }
// .form-control {
//     width: 106%;

// }

// .p-dropdown .form-control{
//     width: 105%;
// }
.p-disabled{
    background-color: #e9ecef;
    opacity: 1;
  }

