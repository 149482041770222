@import "../theme.scss";

.left-menu {
    background: $primary-bglight;
    color: $txtcolor;
    border-top-right-radius: 30px;
    width: 95%;
    height: 100vh;
    min-height: 100%;
    overflow-x: none;
    position: sticky;

    // overflow-y:auto;
    .left-title {
        font-weight: 600;
        color: #9e9e9e;
    }

    .nav-item {
        list-style-type: none;
        width: 85%;
        cursor: pointer;

        .nav-link {
            text-decoration: none;
            color: #40464b;
            width: auto;
            // line-height: 5px;
            // border-radius: 5px;
            text-align: left;
            // border-left: 5px solid #fff;
            // margin-bottom: 10px;
            padding: 0px;
            span {
                // line-height: 20px;

                img {
                    width: 22px;
                    height: 22px;
                    border-radius: 2px;
                }

            }

            p {
                line-height: 15px;
                position: absolute;
                display: inline-block;
                font-size: 15px;
                font-family: var(--primary-font);
                opacity: 1;
                transition: opacity .2s ease in;
            }
        }

        label {
            color: #a3a7ab;
            font-size: 14px;
            font-family: var(--primary-font);
        }

        .nav-link:hover {
            color: $primary;
            background: #f8f5ff;
            // border-left: 5px solid $primary;
            border-right: 8px solid $primary;

            p {
                color: $primary;
            }

        }

        .nav-link.active {
            color: $primary;
            background: #f8f5ff;
            // border-left: 5px solid $primary;
            border-right: 8px solid $primary;
            padding:1px;
        }
      

    }

    .menu-logo{
        fill: $primary !important;
        stroke:$primary !important;
    }
    .menu-item {
        color: #98999d;
        font-weight: 600;
        cursor: pointer;

        span {
            vertical-align: middle;
        }

        p {
            vertical-align: middle;
        }
    }

    .accordion {
        .accordion-header {
            margin-left: -4px !important;

            .accordion-button {
                border-radius: 5px;
                border-bottom: 0 !important;
            }

            .accordion-button:focus {
                box-shadow: none;
                border-bottom: 0 !important;
            }
        }

        .accordion-body {
            .dropdown-item.active {
                color: $primary !important;
                // border-right: 5px solid $primary;
                background: #f8f5ff;
            }

            .dropdown-item:hover {
                background: #f8f5ff;
            }

        }
    }
   .accordion-button-active-icon ::after{
       fill: $primary !important;
       color: $primary !important;
       background-color: $primary !important;

   }
    .accordion-button:not(.collapsed)::after {
        color: $primary !important;
    }
    .accordion-button:not(.collapsed) {
        background: #f8f5ff;
        // border-left: 5px solid $primary;
        border-right: 8px solid $primary;
        border-bottom: 0 !important;
        color: $primary !important;
    }

    .accordion-button.collapsed {

        border-left: 5px solid #fff;
        border-bottom: 0 !important;
    }

    // .accordion-button{
    //     color:#000;
    // }
    .accordion-button:focus {
        background: #f8f5ff;
        // border-left: 5px solid $primary;
        border-bottom: 0 !important;
    }

    // .nav-link.open{
    // color: $primary;
    // background: #f8f5ff;
    // border-left: 5px solid $primary;
    // }


    .drop-down {
        p {
            color: #98999d;
            font-weight: 600;
            cursor: pointer;
        }

    }

    .drop-down {
        p:hover {
            background: #f8f5ff;
            color: $primary;
        }

    }

    .menu-item:hover {
        color: $primary;
        background: #f8f5ff;
        border-left: 5px solid $primary;

    }
}
.menu-logo{
    fill: $primary !important;
    stroke:$primary !important;
}
.left-menu.active {
    width: 52px;
    z-index:999;

    // margin-right:10px !important;
    .navbar-nav {
        .nav-item {
            .nav-link {
                p {
                    display: none;
                }
            }

            label {
                display: none;
            }

            span {
                display: none;
            }
        }

        .accordion {
            .accordion-header {
                .accordion-button {
                    .menu-name {
                        display: none
                    }

                }
            }

            .accordion-button::after {
                display: none;
            }

            .accordion-body {
                width: 200px;
                background: white;
                display: block;
                padding: 10px 15px;
                margin: -10px 10px;
                box-shadow: 5px 5px 5px lightgray;
            }
        }
    }

}

@import '../responsive.scss';

@include lg-min {
    .left-menu {
        width: 98%;

    }

    .left-menu.showMenu {
        width: 98% !important;

        .navbar-nav {
            .nav-item {
                .nav-link {
                    p {
                        display: block;
                    }
                }

                label {
                    display: block !important;
                }

                span {
                    display: block !important;
                }
            }
        }
    }
}

@include md-min {
    .left-menu {
        width: 98%;
    }

    .left-menu.showMenu {
        width: 98% !important;

        .navbar-nav {
            .nav-item {
                .nav-link {
                    p {
                        display: block;
                    }
                }

                label {
                    display: block !important;
                }

                span {

                    display: block !important;

                }
            }
        }
    }
}

@include tab-max {
    .left-menu {
        width: 98%;

        span {
            display: block;
        }
    }

    .left-menu.showMenu {
        width: 98% !important;

        .navbar-nav {
            .nav-item {
                .nav-link {
                    p {
                        display: block;
                    }
                }

                label {
                    display: block !important;
                }

                span {
                    display: block !important;
                }
            }
        }
    }
}

@include tab-min {

    .left-menu.showMenu {
        width: 10px;

        .navbar-nav {
            .nav-item {
                .nav-link {
                    p {
                        display: none;
                    }
                }

                label {
                    display: none;
                }

                span {
                    display: none;
                }
            }
        }
    }
    // .left-menu.active{
    //     width: 98%;
    //     .navbar-nav {
    //         .nav-item {
    //             .nav-link {
    //                 p {
    //                     display: block;
    //                 }
    //             }

    //             label {
    //                 display: block;
    //             }

    //             span {
    //                 display: block;
    //             }
    //         }
    //     }
    // }

}


.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
}


///new///////////////////////////////////////////


.p-tieredmenu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #495057;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    width: auto;
    // height: 1000px;
}

.p-tieredmenu .p-menuitem-link {
    padding: 10px 0px 10px 6px;
    cursor: pointer;
    display: block !important;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
    font-size: 38px;
    display: flex;
    justify-content: center;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
    color: #495057;
    display: flex;
    justify-content: center;
    padding-top: 8px;
    font-size: 12px;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
    display: none;
}

.p-tieredmenu .p-menuitem-active>.p-submenu-list {
    display: block;
    left: 100%;
    top: 0;
    z-index: 1000;
}
.p-tieredmenu .p-submenu-list {
    position: absolute;
    min-width: 100%;
    z-index: 1;
}

// #section > div.sidemenu.me-2.col-md-2 > div > div > ul

///antd
/// 
/// 
.ant-menu-inline-collapsed >.ant-menu-item .ant-menu-item-icon +span,  .ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-item-icon +span,  .ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon +span,  .ant-menu-inline-collapsed >.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon +span,  .ant-menu-inline-collapsed >.ant-menu-item .anticon +span,  .ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon +span,  .ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon +span,  .ant-menu-inline-collapsed >.ant-menu-submenu>.ant-menu-submenu-title .anticon +span {
    display: contents !important;
}

.ant-menu-vertical >.ant-menu-item{
    height: 58px ;
    // line-height: 26px;
    line-height: 10px ;
}

.ant-menu-inline >.ant-menu-item,  .ant-menu-vertical >.ant-menu-item,  .ant-menu-inline >.ant-menu-submenu>.ant-menu-submenu-title,  .ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title {
    height: 58px ;
    // line-height: 26px;
    line-height: 10px ;
}
.ant-menu-inline-collapsed >.ant-menu-item,  .ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,  .ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title,  .ant-menu-inline-collapsed >.ant-menu-submenu>.ant-menu-submenu-title {
    padding-inline:0px !important;
}

.ant-menu-title-content{
    a {
        text-decoration: none !important;
    }
}

.ant-menu-light .ant-menu-item-selected a{
    color: #1677ff !important;
}
.ant-menu-light .ant-menu-submenu-selected{
    color: #1677ff;
    background-color: #e6f4ff !important;
}
.ant-menu-light .ant-menu-item-selected a :hover{
    color: darkblue !important;
}

// .ant-tooltip-open{
//     display: none !important;
// }

.mobile-menu{
    position: fixed !important;
    top: 67px !important;
    left: 0 !important;
    height: 100vh !important;
    width: 100vw !important;
    z-index: 1001 !important;
    background: #00000073 !important;
}