.dayData{
    background-color: white;
    
}

.daysCard{
    background:#efefef;
    padding:40px 7px;
    // width:110px
    
}
.dayscardtxt{
    color:black;
    font-size:18px;
    text-align:center;
}

.daysDetail{
    flex: 0 1 48%;
  display: flex;
   
    background-color: white;
    box-shadow: 0 0 3px 2px whitesmoke;
    p{
    color: black;
    margin-bottom: 0px;
    }
    // .active{
    //     background-color: red;
    // }
    // .a-status{
    //     color: red;
    // }

    // .closed{
    //     background-color: green;
    // }
    // .c-status{
    //     color: green;
    // }
}
.daysDetail{
    &-footer {
        margin-top: auto;
      }
}
.card-inner {
    display:flex;
    flex-direction:column;
  }
.botStatus{
    margin-top: auto;
    height: 30px;
    background-color: green;
    color: white;
}

.monthSelected{
    background-color: #FFF5CE;
    color: #000000;
}
.monthnotselected{
    background-color: white;
}
//

// .container {
//     display:flex;
//     flex-flow :row wrap;
//     justify-content: space-around;
//     // align-items: stretch;
//   }
.activeweek{
    background-color: #FF5439;
}
.a-status{
    color: #FF5439;
    font-weight: bold;
}

.closed{
    background-color: #8DC540;
}
.c-status{
    color: #8DC540;
    font-weight: bold;

}
.unknown{
    background-color: #FFAD48;
}
.u-status{
    color: #FFAD48;
    font-weight: bold;

}

  ////////////////////////////
  // the flex items:
  ///////////////////////////
  
  // .card {
  //   // flex: 0 1 48%;
   
  // }
  
  
  ////////////////////////////
  // the flex container inside the card:
  ///////////////////////////
  
  .card-inner {
    display:flex;
    flex-direction:column;
  }
  
  ////////////////////////////
  // the flex items:
  ///////////////////////////
  
  .pc-card {
    &-footer {
      margin-top: auto;
    }
  }
  
  // cosmetic styles
  * {
    box-sizing:border-box;
  }
  html,body {
    height: 100%;
  }
  body {
    background: #999;  
  }
  
  .container {
    max-width: 1024px;
    margin: 0 auto;
    padding: 20px 0;
  }
  
  .pc-card {
    background: #fff;
    box-shadow:0 0 10px #d5d5d5c2;
    padding: 0px;
    // margin-bottom: 2%;
  }
  
  .card-header {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size:24px;
    line-height: 1.5;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .card-footer {
    // background: rgb(50, 211, 35);
    color:white;
    border-radius:4px;
    padding: 3px;
  }
  .pc-card-body{
        padding: 11px 4px 11px 5px;
    p{
        margin-bottom: 2px;
        color: black;
    }
  }


  .pc-card-dis {
    background: #E6EAEC;
    box-shadow:0 0 10px #d5d5d5c2;
    padding: 0px;
    // margin-bottom: 2%;
  }
  .pc-card-body-dis{
    padding: 9px 4px 46px 5px;
p{
    margin-bottom: 2px;
    color: black;
}
}
  .card-footer-dis{
    background-color: #F4F9EC;
  }
  //


  .amount{
    margin-bottom: 0px;
    color: white;
    font-size: 13px;
  }


  .pc-blue{
      background-color: #24857A;
      color: white;
      padding: 10px 5px;
  }


  .pc-green{
    background-color: #007C4E;
    color: white;
    padding: 10px 5px;
  }