@import '../theme.scss';

.p-treetable{
    .p-treetable-header{
        background: #fff;
        color:#343a40;
        border: 1px solid $primary;
        border-width: 1px 1px 1px 9px;
        font-weight: 700;
        margin: 0px 0 px 3px 4px;
        box-shadow: 1px 2px 8px 2px #d3eee1 !important;
        margin-bottom: 14px;
        border-radius: 5px;
        .react-datepicker-wrapper{
            .react-datepicker__input-container{
                .react-datepicker__close-icon{
                    margin-left:3px;
                }
            }
        }
    }
    .p-treetable-wrapper{
        table{
            .p-treetable-thead{
                tr{
                    th:first-child{
                        width: 200px;
                    }
                }
            }
            .p-treetable-tbody{
                tr.p-highlight{ 
                    
                    background: white;
                    td:first-child{
                        color:#000;
                        float:inherit;
                       button{
                        color: #000;
                        display:  block !important;
                        margin-top:-5px;
                       }
                       button:hover{
                        box-shadow: none;
                        background: transparent;
                    }
                    button:focus{
                        box-shadow: none;
                    }
                    }
                    
                }
                button{
                    display: block;
                    
                }
                
                tr.p-notExpand{
                    background: lightgray;
                    button{
                        display: block;
                        
                    }
                    button:hover{
                        box-shadow: none;
                        background: transparent;
                    }
                    button:focus{
                        box-shadow: none;
                    }
                }
               tr{
                background: #e9e9e92e;
                td{
                    
                    button.p-treetable-toggler{
                        float: left;
                        
                        // display: none;
                        
                    }
                }
               }
            }
        }
    }
}
#search-icon{
    font-size: 12px !important;
    line-height: 2rem !important;
}
.chart-of-account{
    .scnd-header{
        background:#C9DFD5;
    }
}
