.block{
    background-color: antiquewhite;
    color: black;
    margin-left: 0px;
    margin-bottom: 8px;
    padding: 5px 2px 5px 5px;
}

.block-head{
    background-color: rgb(208, 248, 144);
    color: black;
    margin-left: 0px;
    margin-bottom: 8px;
    padding: 5px 2px 5px 5px;
}

.block-empty{
    background-color: #f3eee7;
    color: black;
    margin-left: 0px;
    margin-bottom: 8px;
    padding: 5px 2px 5px 5px;
}

.shorten{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}