.chart-of-account{
    border-radius:20px;
    min-height:100%;
    .btn-acnt{
        background: #00bc9d;
    }
    .btn-find-acc{
       color:#6daced;
    }
  
}
.p-dropdown{
    .p-inputtext{
        padding:7px 4px;
        font-size: 14px;

    }
}
.p-inputtext{
    font-size: 14px;
}
.p-dialog-resizable{
.p-dialog{
    .p-dialog-header{
        background:#f2f2f3  !important;
        .pr_id_1_header{
            background:#000 !important;
        }
    }
    .p-dialog-footer{
        .btn-cancel{
            color: #ffffff;
            background-color: #f74343;
            padding: 5px;
        }
        .btn-save{
            padding: 5px;
        }
    }
}
}