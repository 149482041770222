.sidemenu.activeMenu{
    width: 50px;
}
.sidemenu.showMenu{
    display: none;
}
.child-container{
    z-index: 2;
}
@import '../responsive.scss';
@include md-min{
    .sidemenu.showMenu{
        display: block !important;
    }
}
@include tab{
    .sidemenu.activeMenu{
        display: none;
        // width: 200px;
    }
}