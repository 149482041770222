// @import "./theme.scss";
@import "../theme.scss";
.topbar{
    background: $primary-bglight;
    color: $txtcolor;
    .navbar{
        .navbar-brand{
            font-weight: 600;
            width: 14%;
        }
        .menu-icon{
            line-height: 3;
            cursor: pointer;
            svg{
                
                vertical-align: bottom;
            }
            
        }
        .nav-item{
            .search-group{
                border-color: #eaeaea;
                input{
                    border-right:none;
                    color:#747d84;
                    font-weight: 500;
                    
                }
                span{
                    border-left:none;
                    background: #fff;
                }
            }
        }
        .dropdown-toggle{
            border:none;
        }
        .dropdown-toggle::after{
                display: none;
        }
        .dropdown-toggle:hover{
            border: none;
        }
        
    }
}