.dash{
    p{
        font-size: 14px;
    }
}
.dash-widget-header {
    align-items: center;
    display: flex;
}

.dash-widget-icon {
    align-items: center;
    border-radius: 10px;
    color: #fff;
    display: inline-flex;
    font-size: 1.875rem;
    height: 4rem;
    justify-content: center;
    text-align: center;
    width: 4rem;
    background: linear-gradient(90deg, rgba(144,199,64,1) 0%, rgba(144,199,64,1) 40%, rgba(0,212,255,1) 100%);
    margin-right: 20px;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    // color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: #fcd703;
    // transition: var(--bs-progress-bar-transition);
}

.progress {
    height: 7px;
}