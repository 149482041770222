@import "../theme.scss";
.login-cont{
    width: 100%;
    height:1000px;
    // background: #f7f8f9;
    .cont-login{
        border-radius: 20px;
    }
    .login-subtitle{
        color:#4c4c4c;
        font-weight: 500;
    }
    .form-control-label{
        color:#455560;
        font-weight: 500;
        font-size: 18px;
    }
    input{
        font-weight: 500;
        width: 95%;
    }
    .form-check-input{
        width: 15%;
    }
    a{
        text-decoration: none;
        font-weight: 500;
        
    }
    .btn-login{
        // background:$primary;
        background: $primary;
        font-size: 20px;
        color: white;
        &:hover,
        &:active {
            background:$primary;
            font-size: 20px;
            color: white;
        }
        &:hover:active,
        &:focus,
        &.active {
            background: $primary;
            font-size: 20px;
            color: white;
        }
    }
 
    .input-group{
        input{
            border-right: none;
            cursor: pointer;
        }
        span{
        border-left: none;
        background:transparent;
        }
    }
 

.login-spin{
   
    .p-progress-spinner {
        position: relative;
        margin: 0 auto;
        width: 35px;
        height: 25px !important;
        display: inline-block;
    }

    @keyframes p-progress-spinner-color {
        100%,
        0% {
            stroke: #ffa700;
        }
        40% {
            stroke: #ffa700;
        }
        66% {
            stroke: #ffa700;
        }
        80%,
        90% {
            stroke: #ffa700;
        }
    }
}
}